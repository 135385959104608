import { useState, useRef, useEffect } from 'react'

// Deps
import Placeholder from '/views/partials/placeholder'
import Slider from '/views/partials/slider'
import Img from '@wearetla/tla-essentials-tools/partials/img';

const GallerySlider = ({ slides, className }) => {
	const _mounted = useRef(false);
	const mainSliderRef = useRef(null);
	const thumbSliderRef = useRef(null);

	const [activeSlide, setActiveSlide] = useState(0);

	useEffect(() => {
		_mounted.current = true;

		return () => {
			_mounted.current = false;
		}
	}, [])

	const slideChange = () => {
		if(_mounted.current) {
			if (mainSliderRef.current) {
				let index = mainSliderRef.current.realIndex;
				setActiveSlide(index);
				if (thumbSliderRef.current) {
					try {
						thumbSliderRef.current.slideTo(index);
					}
					catch(e) {
						console.warn('Slide change error', e);
					}
				}
			}
		}
	}

	const selectSlide = (index) => {
		if(_mounted.current) {
			mainSliderRef.current.slideToLoop(index);
		}
	}

	if (!(slides && slides.length === 0)) {
		return (
			<section className={'section gallery-slider ' + className}>
				{slides && slides.length !== 0 ?
					<div className="slider-sliderwrap">
						<Slider
							className="sliderwrap-slider"
							params={{
								loop: true,
								onSwiper: (instance) => {
									mainSliderRef.current = instance;
								},
								onSlideChange: () => {
									slideChange();
								}
							}}>
							{slides.map((slide, nth) => (
								<Img
									className="slider-slide"
									src={slide.image}
									key={nth} />
							))}
						</Slider>
						<Slider
							className="sliderwrap-thumbs"
							params={{
								centeredSlides: true,
								centeredSlidesBounds: true,
								slidesPerView: 3,
								touchRatio: 0.2,
								watchOverflow: true,
								navigation: true,
								onSwiper: (instance) => {
									try {
										thumbSliderRef.current = instance;
									}
									catch(e){
										console.warn('Swiper load error', e);
									}
								},
							}}>
							{slides.map((slide, nth) => (
								<div className="thumbs-thumb" key={'thumb-' + nth}>
									<button className={'thumb-btn' + (activeSlide === nth ? ' active' : '')} onClick={() => { selectSlide(nth); }}>
										<div className="thumb-imagewrap" style={{ backgroundImage: `url(${slide.thumb})` }}></div>
									</button>
								</div>
							))}
						</Slider>
					</div>
					:
					<Placeholder className="slider-sliderwrap" />
				}
			</section>
		)
	}
	else { return false; }
}

export default GallerySlider