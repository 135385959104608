import '/assets/styles/partials/mapelement.scss';

import config from '/config';

// Deps
import GoogleMapReact from 'google-map-react';

// Partials
import Icon from '@wearetla/tla-essentials-tools/partials/icon';

export const MapElement = ({className, children, zoom, lat, lng, mapOptions, ...props}) => {
	const createOptions = (maps) => {
		const inputOpts = typeof mapOptions === 'object' ? mapOptions : (mapOptions ? mapOptions(maps) : {});
		return {
			streetViewControl: false,
			mapTypeControl: false,
			fullscreenControl: false,
			zoomControl: true,
			styles: props.theme ? props.theme : (config.maps.theme ? config.maps.theme : []),
			...inputOpts,
		}
	}

	return (
		<div className={'mapelement ' + className}>
			<GoogleMapReact
				bootstrapURLKeys={{ key: config.maps.apiKey }}
				defaultCenter={{
					lat: lat,
					lng: lng,
				}}
				options={createOptions}
				defaultZoom={zoom}>
				{children}
			</GoogleMapReact>
		</div>
	)
}

MapElement.defaultProps = {
	className: '',
	zoom: 19,
	lat: 39.934383,
	lng: 32.8877025,
	theme: false,
}

export const MapMarker = ({...props}) => {
	return (
		<Icon className="mapelement-marker" name="logo" />
	)
}