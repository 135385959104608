// Deps
import { useMemo } from 'react'

// Partials
import Link from '@wearetla/tla-essentials-tools/partials/link';
import Placeholder from '/views/partials/placeholder'

// Context
import { useNavigator } from '@wearetla/tla-essentials-tools/utilities/navigator';
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints';
import { useApplication } from '/utilities/application';


const StaticPageMenu = ({ placeholder, ignoreActive, emptyPlaceholder = 'Bir konu seçin.', ...props }) => {
	const { redirect, getRoutePath, activeRoute } = useNavigator();
	const { mobile } = useBreakpoints();
	const { isApplication } = useApplication();

	// const [links, activeElem] = useLinks(props.elems, ignoreActive);

	const { links, activeElem } = useMemo(() => {
		let activeLink = false;
		
		const newLinks = props.elems ? props.elems.reduce((links, { showOnApp, ...elem }, nth) => {

			if(isApplication && showOnApp === false) {
				return links;
			}
			
			else if(elem.raw) {
				const active = ignoreActive ? false : activeRoute?.match.url === elem.href;
				const newLink = {
					...elem,
					active: false,
					url: elem.href,
				}

				if(active) {
					activeLink = {nth: nth, ...newLink};
				}

				return [...links, newLink];
			}
			else {		
				const elemURL = getRoutePath(elem.href, elem.params);
				const active = ignoreActive ? false : activeRoute?.match.url === elemURL;
	
				const newLink = {
					...elem,
					active: active,
					url: elemURL,
				};
	
				if(active) {
					activeLink = {nth: nth, ...newLink};
				}
				
				return [...links, newLink];
			}
		}, []) : false;

		return {
			links: newLinks,
			activeElem: activeLink
		};
	}, [props.elems, isApplication])

	if(!mobile) {
		return (
			<ul className="menu-list">
				{links ?
					<>
						{links.map((link, nth) => {
							const {url, active, title, raw, ...linkProps} = link;
							return (
								<li className="list-item" key={nth}>
									<Link
										raw
										// scroll={false}
										{...linkProps}
										href={url}
										className={
											'item-link' + (active ? ' active' : '')
										}>
										{title}
									</Link>
								</li>
							)
						})}
					</>
					:
					<>
						<Placeholder className="list-item" />
						<Placeholder className="list-item" />
						<Placeholder className="list-item" />
						<Placeholder className="list-item" />
						<Placeholder className="list-item" />
					</>
				}
			</ul>
		)
	}
	else {
		return (
			<div className="menu-selectnav">
				{links ?
					<>
						<label htmlFor="selectnav-select" className="selectnav-label">
							<span>{activeElem?.title ?? emptyPlaceholder}</span>
						</label>
						<select
							className="selectnav-select"
							id="selectnav-select"
							onChange={(e) => {
								const link = links[parseInt(e.target.value)];
								redirect(link.url,
								{},
								{
									raw: true,
									hard: link.type === 'a',
									scroll: false,
								});
							}}
							defaultValue={activeElem ? activeElem.nth.toString() : undefined}>
							{links.map((link, nth) => (
								<option
									key={nth}
									value={nth}>
									{link.title}
								</option>
							))}
						</select>
					</>
					:
					<>
						<Placeholder className="selectnav-label" />
					</>
				}
			</div>
		)
	}
}

export default StaticPageMenu