import { useEffect, useState, useRef } from 'react'

const useFixedSideMenu = (mobile) => {
	const containerElem = useRef(false);
	const sidebarElem = useRef(false);
	const [fixed, setFixed] = useState([false, false]);

	
	useEffect(() => {
		containerElem.current = document.querySelector('.section.static-wrap');
		sidebarElem.current = document.querySelector('.section.static-menu');
	}, [])
	
	useEffect(() => {
		const handleScroll = () => {
			if(containerElem.current) {
				const offset = containerElem.current.getBoundingClientRect();
				if(!mobile && offset.top < 20) {
					setFixed([true, offset.height + offset.top <= (sidebarElem.current.offsetHeight + 20)]);
				}
				else {
					setFixed([false, false]);
				}
			}
		}
		
		window.removeEventListener('scroll', handleScroll);
		window.addEventListener('scroll', handleScroll);
		
		return () => {
			window.removeEventListener('scroll', handleScroll);
		}
	}, [mobile])

	return fixed;
}

export default useFixedSideMenu;