import '/assets/styles/partials/slider.scss';

import loadable from '@loadable/component';
import SliderPlaceholder from '@wearetla/tla-essentials-tools/partials/slider/slider-placeholder';

const LazySlider = loadable(() => import('@wearetla/tla-essentials-tools/partials/slider/slider'), { ssr: false });

const Slider = (props) => {
	return (
		<LazySlider {...props} fallback={<SliderPlaceholder {...props} />} />
	)
}

export default Slider;

Slider.defaultProps = {
	navPrevIcon: 'arrow-left',
	navNextIcon: 'arrow-right',
}