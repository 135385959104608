import '/assets/styles/pages/static.scss';
import '/assets/styles/pages/stores.scss';

import React, { useState, useEffect } from 'react'

// Partials
import Placeholder from '/views/partials/placeholder'
import { MapElement, MapMarker } from '/views/partials/mapelement'
import StaticPageMenu from '/views/partials/static-page-menu';
import Link from '@wearetla/tla-essentials-tools/partials/link';
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Icon from '@wearetla/tla-essentials-tools/partials/icon';

// Sections
import GallerySlider from '/views/sections/gallery-slider'

// Hooks
import useFixedSideMenu from '/hooks/fixed-side-menu'

// Services
import siteServices from '/services/site'

// Context
import { useBreakpoints } from "@wearetla/tla-essentials-tools/utilities/breakpoints";
import { useHead } from '@wearetla/tla-essentials-tools/utilities/head';

// Data
const defaultHead = {
	title: 'Mağazalarımız',
}

// Data Endpoints
const getStores = (ctx) => {
	return siteServices.getStores(ctx);
}

const getStoreData = (id, ctx) => {
	if(id) {
		return siteServices.getStore(id, ctx);
	}
	else {
		return new Promise((resolve) => false);
	}
}

const generateHead = (store) => ({
	title: store.branch_name,
	description: store.address,
})

const Stores = ({ match, serverProps }) => {
	const { mobile } = useBreakpoints();
	const { setHead } = useHead();

	const [stores, setStores] = useState(serverProps.stores ? serverProps.stores : null);
	const [store, setStore] = useState(serverProps.store ? serverProps.store : false);

	const [sideMenuFixed, sideMenuFixedEnd] = useFixedSideMenu(mobile);
	const storeID = parseInt(match.params.id);

	useEffect(() => {
		if(stores === null) {
			getStores().then((payload) => {
				setStores(payload);
			})
		}
	}, [stores])

	useEffect(() => {
		if(storeID && (store === false || (store && store.id !== storeID))) {
			setStore(null);
			getStoreData(storeID).then((payload) => {
				setStore(payload);
				setHead(generateHead(payload));
			})
		}
		else if(!storeID && match.route) {
			setStore(false);
			setHead(defaultHead, true, match.route);
		}
	}, [storeID, store, match.route])

	return (
		<main className="page static stores">
			<div className={`section static-wrap wrapper${sideMenuFixed ? ' fixed' : ''}${sideMenuFixedEnd ? ' fixed-end' : ''}`} id="faq-content">
				<nav className="section static-menu">
					<strong className="menu-title">
						<Icon className="title-icon" name="store" />
						Mağazalarımız
					</strong>
					<StaticPageMenu
						emptyPlaceholder="Bir Mağaza Seçin"
						elems={stores ? stores.map((listedStore, nth) => ({
							title: listedStore.branch_name,
							href: 'stores',
							params: { slug: listedStore.slug, id: listedStore.id },
						})) : false}
						match={match}
						mobile={mobile} />
				</nav>
				<div className="section static-content">
					<h1 className="content-title">
						{!storeID ?
							<>Mağazalarımız</>
							:
							<>
								{store ?
									<>{store.branch_name}</>
								:
									<Placeholder />
								}
							</>
						}
					</h1>

					{storeID ?
						<>
							{store ?
								<>
									<div className="stores-detail-top">
										<div className="top-section">
											<strong className="section-title">{store.branch_name}</strong>
											{store.address &&
												<div className="section-block" dangerouslySetInnerHTML={{__html: store.address}} />
											}
											{(store.phone_number || store.phone_number_2) &&
												<div className="section-block">
													{store.phone_number &&
														<p>Tel: {store.phone_number}</p>
													}
													{store.phone_number_2 &&
														<p>{store.phone_number ? '' : 'Tel: '}{store.phone_number_2}</p>
													}
												</div>
											}
											{store.email &&
												<div className="section-block">E-mail: {store.email}</div>
											}
										</div>
										{store.working_hours &&
											<div className="top-section">
												<strong className="section-title">Çalışma Saatleri</strong>
												{store.working_hours &&
													<div className="section-block" dangerouslySetInnerHTML={{__html: store.working_hours}} />
												}
											</div>
										}
									</div>
									{store.description &&
										<div className="stores-detail-description wysiwyg" dangerouslySetInnerHTML={{__html: store.description}} />
									}
									{store.images &&
										<GallerySlider
											key={'gallery-' + store.id} 
											className="stores-detail-gallery"
											slides={store.images} />
									}

									{store.latitude && store.longitude &&
										<MapElement
											className="stores-detail-map"
											lat={parseFloat(store.latitude)}
											lng={parseFloat(store.longitude)}>
											<MapMarker className="marker"
												lat={parseFloat(store.latitude)}
												lng={parseFloat(store.longitude)} />
										</MapElement>
									}
								</>
								:	
								<>
									<div className="stores-detail-top">
										<div className="top-section">
											<Placeholder className="section-title" />
											<Placeholder className="section-block" />
											<Placeholder className="section-block" />
											<Placeholder className="section-block" />
										</div>
										<div className="top-section">
											<Placeholder className="section-title" />
											<Placeholder className="section-block" />
										</div>
									</div>
									<div className="stores-detail-description wysiwyg">
										<Placeholder />
										<Placeholder />
										<Placeholder />
									</div>
									<GallerySlider key="gallery-placeholder" className="stores-detail-gallery" slides={false} />
									<Placeholder className="stores-detail-map" />
								</>
							}
						</>
						:
						<>
							{(stores && stores.length !== 0) ?
								<ul className="section stores-list">
									{stores.map((store, nth) => (
										<li className="list-store" key={nth}>
											<Link
												className="store-link"
												href="stores"
												params={{ id: store.id, slug: store.slug }}>
												<div className="store-imagewrap">
													<Img
														src={store.image}
														className="store-image" />
												</div>
												<strong className="store-title">
													{store.branch_name}
												</strong>
											</Link>
										</li>
									))}
								</ul>
								:
								<ul className="section stores-list">
									{[1, 2, 3, 4, 5, 6, 7].map((store, nth) => (
										<li className="list-store" key={nth}>
											<Placeholder className="store-imagewrap" />
											<Placeholder className="store-title" />
										</li>
									))}
								</ul>
							}
						</>
					}
				</div>
			</div>
		</main>
	)
}

Stores.defaultProps = {
	serverProps: {
		stores: false,
		store: false,
	}
}

// Stores.getInitialProps = async ({ req, res, match, ...ctx }) => {
// 	const defaultData = {
// 		error: false,
// 		stores: false,
// 		store: false,
// 	}

// 	return new Promise((resolve) => {
// 		try {
// 			if (req) {
// 				const promiseList = [
// 					getStores(ctx),
// 					(match.params.id && match.params.slug) ? getStoreData(match.params.id, ctx) : undefined,
// 				]

// 				Promise.all(promiseList).then(([
// 					storesPayload,
// 					detailPayload,
// 				]) => {
// 					resolve({
// 						...defaultData,
// 						stores: storesPayload,
// 						store: detailPayload,
// 						head: detailPayload ? generateHead(detailPayload) : defaultHead,
// 					})
// 				}).catch(() => {
// 					resolve({
// 						...defaultData,
// 						error: true,
// 					})
// 				})
// 			}
// 			else {
// 				resolve(defaultData)
// 			}
// 		}
// 		catch (e) {
// 			console.warn('Stores fetch error: ', e);
// 			resolve({
// 				...defaultData,
// 				error: true,
// 			})
// 		}
// 	});
// }

export default Stores;